import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import GoogleMapSnapshot from "./GoogleMapSnapshot"; // Replacing HereMapSnapshot
import CompDrawingTool from "@src/modules/VehicleMovementPlan/parts/drawing-tools/DrawingTool";

interface Props {
  open: boolean;
  onClose(): void;
  isSaving: boolean;
  onExportPngData(pngData: string): void;
}

const CompDrawingToolDialog = (props: Props) => {
  const { open, onClose, isSaving, onExportPngData } = props;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [status, setStatus] = useState<"googlemap" | "captured">("googlemap");
  const [bgDataURLString, setBgDataURLString] = useState<string>("");
  const showingGoogleMap = status === "googlemap";
  const mapCaptured = status === "captured";
  const [exportTrigger, setExportTrigger] = useState(0);
  const [zoom, setZoom] = useState<number>(15);
  const [center, setCenter] = useState<any>({ lng: 151.2093, lat: -33.8396 });

  const handleCaptured = (dataURLString: string) => {
    console.log("🖼️ Captured Map Image:", dataURLString); // Debugging
    if (!dataURLString) {
      console.error("❌ Failed to capture map image.");
      return;
    }
    setBgDataURLString(dataURLString);
    setStatus("captured");
  };

  const handleExport = async (svgData: string) => {
    if (!canvasRef.current) {
      console.error("❌ Canvas reference is null.");
      return;
    }

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    if (!context) {
      console.error("❌ Failed to get 2D context.");
      return;
    }

    const width = 700;
    const height = 410;
    canvas.width = width;
    canvas.height = height;

    let mapImage = new Image();
    let svgImage = new Image();
    let imagesLoaded = 0;

    const checkImagesLoaded = () => {
      imagesLoaded++;
      if (imagesLoaded === 2) {
        console.log("✅ Both images loaded successfully.");
        context.clearRect(0, 0, width, height);
        context.drawImage(mapImage, 0, 0, width, height);
        context.drawImage(svgImage, 0, 0, width, height);
        onExportPngData(canvas.toDataURL("image/png"));
      }
    };

    mapImage.onload = checkImagesLoaded;
    mapImage.onerror = () => console.error("❌ Failed to load map snapshot.");
	mapImage.crossOrigin = "anonymous";
    mapImage.src = bgDataURLString;

    svgImage.onload = checkImagesLoaded;
    svgImage.onerror = () => console.error("❌ Failed to load SVG overlay.");
    svgImage.src = svgData;
  };

  return (
    <Wrapper>
      <Dialog maxWidth="xl" open={open} onClose={() => showingGoogleMap && onClose()}>
        {!mapCaptured && (
          <GoogleMapSnapshot
            zoom={zoom}
            center={center}
            onZoom={setZoom}
            onCenterChange={setCenter}
            onCaptured={handleCaptured}
          />
        )}
        {mapCaptured && (
          <CompDrawingTool bgDataURLString={bgDataURLString} exportTrigger={exportTrigger} onExport={handleExport} />
        )}

        {!showingGoogleMap && (
          <DialogActions>
            {mapCaptured && (
              <>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="default" variant="contained" onClick={() => setStatus("googlemap")}>
                  Back to Map
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSaving}
                  onClick={() => setExportTrigger(exportTrigger + 1)}
                >
                  {isSaving ? (
                    <>
                      <Spinner size={16} /> SAVING
                    </>
                  ) : (
                    "SAVE SITEMAP"
                  )}
                </Button>
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
      <CanvasPanel ref={canvasRef} />
    </Wrapper>
  );
};

// Styled Components
const Wrapper = styled.div``;

const CanvasPanel = styled.canvas`
  display: none;
`;

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
`;

export default CompDrawingToolDialog;
