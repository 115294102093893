import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import IconButton from "@material-ui/core/IconButton";
import SatelliteIcon from "@material-ui/icons/Satellite";
import MapIcon from "@material-ui/icons/Map";
import { LoadScript, GoogleMap, Marker, Autocomplete } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "@src/constants";

const mapContainerStyle = {
  width: "710px",
  height: "400px",
};

// ✅ Define libraries array outside the component to avoid unnecessary reloading
const googleMapsLibraries: "places"[] = ["places"];

// 📍 Default Location: Boral North Sydney HQ
const defaultCenter = { lat: -33.8396, lng: 151.2093 };

interface Props {
  zoom: number;
  center: { lat: number; lng: number };
  onZoom(newZoom: number): void;
  onCenterChange(newCenter: { lat: number; lng: number }): void;
  onCaptured(dataURLString: string): void;
}

const GoogleMapSnapshot = (props: Props) => {
  const { zoom, center, onZoom, onCenterChange, onCaptured } = props;
  const mapRef = useRef<google.maps.Map | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [mapType, setMapType] = useState<"roadmap" | "satellite">("roadmap");

  useEffect(() => {
    if (!center.lat || !center.lng) {
      onCenterChange(defaultCenter);
    }
  }, [center, onCenterChange]);

  useEffect(() => {
    // ✅ Apply styles dynamically when the component mounts
    const interval = setInterval(() => {
      const pacContainer = document.querySelector(".pac-container") as HTMLElement;
      if (pacContainer) {
        pacContainer.style.zIndex = "99999";
        pacContainer.style.position = "absolute";
        pacContainer.style.top = "auto";
        pacContainer.style.left = "auto";
        pacContainer.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.3)";
        clearInterval(interval); // Stop interval once applied
      }
    }, 500);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  const handleSearchLoad = (autocompleteInstance: google.maps.places.Autocomplete) => {
    autocompleteRef.current = autocompleteInstance;
  };

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry && place.geometry.location) {
        const newCenter = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        console.log("📍 New Location Selected:", newCenter);
        onCenterChange(newCenter);
        mapRef.current?.panTo(newCenter);
      }
    }
  };

  const toggleMapType = () => {
	const newType = mapType === "roadmap" ? "satellite" : "roadmap";
	setMapType(newType);
	mapRef.current?.setMapTypeId(newType);
  };

  const handleCapture = () => {
    const googleStaticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${center.lat},${center.lng}&zoom=${zoom}&size=710x400&key=${GOOGLE_MAPS_API_KEY}`;
    console.log(googleStaticMapUrl);
    onCaptured(googleStaticMapUrl);
  };

  return (
    <Wrapper>
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={googleMapsLibraries}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={zoom}
          center={center.lat ? center : defaultCenter}
          onLoad={handleLoad}
          onIdle={() => {
            if (mapRef.current) {
              const newZoom = mapRef.current.getZoom();
              const newCenter = mapRef.current.getCenter();
              onZoom(newZoom);
              onCenterChange({ lat: newCenter.lat(), lng: newCenter.lng() });
            }
          }}
          options={{
            draggable: true,
            scrollwheel: true,
			gestureHandling: "cooperative",
            mapTypeControl: false,
            fullscreenControl: true,
            streetViewControl: false,
            zoomControl: false,
			panControl: false,
          }}
          onClick={(e) => {
            const lat = e.latLng?.lat();
            const lng = e.latLng?.lng();
            if (lat && lng) {
              onCenterChange({ lat, lng });
              mapRef.current?.panTo({ lat, lng });
            }
          }}
        >
          <Marker
            position={center.lat ? center : defaultCenter}
            draggable
            onDragEnd={(e) => {
              const lat = e.latLng?.lat();
              const lng = e.latLng?.lng();
              if (lat && lng) {
                onCenterChange({ lat, lng });
              }
            }}
          />

          {/* ✅ Autocomplete with Proper Restriction to Australia */}
          <Autocomplete
            onLoad={handleSearchLoad}
            onPlaceChanged={handlePlaceChanged}
            options={{
              types: ["geocode"], // Suggest only addresses
              componentRestrictions: { country: "AU" }, // Restrict to Australia
            }}
          >
            <SearchBoxContainer>
              <input ref={inputRef} type="text" placeholder="Search for a location..." />
            </SearchBoxContainer>
          </Autocomplete>
        </GoogleMap>
      </LoadScript>
      {/* ✅ Restored Capture Button */}
	  <ToggleMapTypeButton onClick={toggleMapType}>{mapType === "roadmap" ? <SatelliteIcon /> : <MapIcon />}</ToggleMapTypeButton>
      <CaptureIcon onClick={handleCapture}>
        <CameraAltIcon />
      </CaptureIcon>
    </Wrapper>
  );
};

const ToggleMapTypeButton = styled(IconButton)`
  position: absolute;
  bottom: 120px;
  left: 20px;
  background: #fff;
  color: #0a9688;
`;

// Styled Components
const Wrapper = styled.div`
  position: relative;
`;

const SearchBoxContainer = styled.div`
  position: absolute;
  top: 15px; /* Slightly lower */
  left: 55%; /* Moved slightly to the right */
  transform: translateX(-50%);
  width: 90%;
  max-width: 350px;
  background: #fff;
  padding: 8px;
  border-radius: 8px;
  z-index: 99999; /* Ensure it's above other elements */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
  }
`;

const CaptureIcon = styled(IconButton)`
  position: absolute;
  bottom: 170px;
  left: 20px;
  background: #fff;
  color: #0a9688;
`;

export default GoogleMapSnapshot;
