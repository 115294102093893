import React from 'react'
import styled from 'styled-components'
import { ProjectTypeId, normalizeProjectTypeId } from '@src/types/ProjectTypeId'
import FormPanel from '@src/components/FormPanel/FormPanel'
import FormPAETable, { PAETableRowType } from '@src/components/Form/PAETable'
import useModal from '@src/components/FormModal/useModal'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { useParams } from 'react-router'
import { DRSPageType, DRSSectionName, mapDRSPageTypeToPAE, mapDRSPageTypeToRoleId } from '@src/modules/DRS/DRSPage'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import MarginTop from '@src/components/Layout/MarginTop'
import CompCreateButton from '@src/components/Buttons/CreateButton'
import { useDRS, transformSectionNameToDataSectionName } from '@src/modules/DRS/useDRS'
import Modal from '@src/components/FormModal/Modal'
import { useAppStore } from '@src/AppStore'
import _get from 'lodash/get'
import { PAE } from '@src/types/drs/PAE'
import { tripleUp } from '@src/modules/DRS/utils/tripleUp'
import { getSupplierAgreementActivityRowData } from '../utils/getSupplierAgreementActivityRowData'
import { transformFromFormData } from '../utils/transformFromFormData'
import { PackFormType } from '@src/types/PackFormType'
import DRSSubcontractorTransportProfilingsFields, { DRSSubcontractorTransportProfilingRow } from './TransportProfilingFields'
import { SubcontractorTransportProfilingPAE } from '@src/types/drs/subcontractor/SubcontractorTransportProfiling'
import { apiDRSSubcontractorTransportProfilingUpdate } from '@src/apis/drs/drs-subcontractor-transport-profiling-update'
import { TransportTypeId } from '@src/types/TransportTypeId'
import { mapMoreActions } from '@src/modules/DRS/utils/mapMoreActions'
import { PAETableMoreActionType } from '@src/components/Form/comps/ActionMenu'
import { doubleUp } from '@src/modules/DRS/utils/doubleUp'
import { apiDRSSubcontractorTransportProfilingRemove } from '@src/apis/drs/drs-subcontractor-transport-profiling-remove'

const NULL_VALUES = {
  comments: null,
  docketNumber: null,
  endTime: null,
  quantity: null,
  startTime: null,
  supplier: null,
  supplierAgreement: null,
  weight: null,
}
const ZERO_VALUES = {
  comments: null,
  docketNumber: null,
  endTime: null,
  quantity: '0',
  startTime: null,
  supplier: null,
  supplierAgreement: null,
  weight: null,
}

interface Props { }
const CompDRSSubcontractorTransportProfiling = (props: Props) => {
  const { id } = useParams<{ id: string }>()
  const { drs, drsId, updateViaPath, updateSectionViaPath, drsPageType, fetchDRS, refresh } = useDRS()
  const isPlan = drsPageType === DRSPageType.Plan
  const isEdit = drsPageType === DRSPageType.Edit
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit
  const { alertError, alertDone, } = useSnackbar()
  const { confirm } = useConfirm()
  const [open, modalData, openAndSetData, closeModal] = useModal<DRSSubcontractorTransportProfilingRow>({})
  const { depotId, stateId, serialId } = useAppStore()
  if (drs === null || drs.subcontractor.asphalt === null) {
    return null
  }
  const handleSave = async (form: DRSSubcontractorTransportProfilingRow) => {
    const finalFormData = transformFromFormData(form, drs.jobDetails.date)
    if (modalData === null) {
      const retained = getRetained()
      update([
        ...retained,
        {
          workType: {
            id: ProjectTypeId.Asphalt,
          },
          [updatingColumn]: {
            ...finalFormData,
            transportType: {
              id: TransportTypeId.Profiling,
            }
          }
        }
      ])
    } else {
      updateItemByValues(form, finalFormData)
    }
  }
  const handleRevert = async (form: DRSSubcontractorTransportProfilingRow) => {
    updateItemByValues(form, NULL_VALUES)
  }
  const handleMarkZero = async (form: DRSSubcontractorTransportProfilingRow) => {
    updateItemByValues(form, ZERO_VALUES)
  }
  const handleDelete = async (form: any[]) => {
    console.log("🔍 Received form data:", form);

    let recordsAPI = [];
    const formData = typeof form === 'object' && form.length > 0 ? form : [form];

    console.log("📌 Processed formData:", formData);

    const retained = getRetained();
    console.log("📂 Retrieved retained records:", retained);

    if (formData.length > 0) {
        console.log("🧐 Checking formData length:", formData.length);

        if (retained.length === formData.length) {
            console.log("✅ Retained and formData have the same length. Using retained records.");
            recordsAPI = retained;
        } else {
            console.log("🔄 Matching formData items with retained records...");
            for (let i = 0; i < formData.length; i++) {
                if (retained[formData[i]['_no_']]) {
                    recordsAPI.push(retained[formData[i]['_no_']]);
                    console.log(`📌 Added record for _no_: ${formData[i]['_no_']}`);
                } else {
                    console.warn(`⚠️ No matching retained record found for _no_: ${formData[i]['_no_']}`);
                }
            }
        }
    } else {
        console.log("❌ No formData provided.");
    }

    if (recordsAPI.length > 0) {
        console.log("🚮 Deleting records:", recordsAPI);
        deleteRecords(recordsAPI);
    } else {
        console.warn("⚠️ No records found to delete.");
    }
};

  const deleteRecords = async (data: any[]) => {
    try {
      await apiDRSSubcontractorTransportProfilingRemove({
        drsId: drsId,
        assets: data,
        reqSid: serialId,
        reqRole: mapDRSPageTypeToRoleId(drsPageType),
      })
      refresh()
      closeModal()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  const handleDuplicate = async (form: DRSSubcontractorTransportProfilingRow) => {
    const retained = getRetained()
    update([
      ...retained,
      {
        workType: {
          id: ProjectTypeId.Asphalt,
        },
        [updatingColumn]: {
          ...NULL_VALUES,
          supplier: form.supplier,
          supplierAgreement: form.supplierAgreement,
          transportType: {
            id: TransportTypeId.Profiling,
          }
        }
      }
    ])
  }
  const getRetained = () => {
    const retainedAssets = drs.subcontractor.asphalt.profilingTransport
    return retainedAssets
  }
  const updateItemByValues = (form: DRSSubcontractorTransportProfilingRow, values: any) => {
    const retained = getRetained()
    if (form._no_ === undefined || form._type_ === undefined) {
      return
    }
    const updatingIndex = form._no_
    const data = [
      ...retained.slice(0, updatingIndex),
      {
        ...retained[updatingIndex],
        [updatingColumn]: {
          ...values,
          transportType: {
            id: TransportTypeId.Profiling,
          }
        }
      },
      ...retained.slice(updatingIndex + 1),
    ]
    update(data)
  }
  const update = async (data: any[]) => {
    try {
      await apiDRSSubcontractorTransportProfilingUpdate({
        drsId: drsId,
        assets: data,
        reqSid: serialId,
        reqRole: mapDRSPageTypeToRoleId(drsPageType),
      })
      refresh()
      closeModal()
      alertDone()
    } catch (e) {
      alertError()
    }
  }
  const getTableData = () => {
    const assets: SubcontractorTransportProfilingPAE[] = drs.subcontractor.asphalt.profilingTransport
    const packFormType = PackFormType.DRS_SUBCONTRACTOR_TRANSPORT
    // const tripled = tripleUp<SubcontractorTransportProfilingPAE>(assets, drsPageType, packFormType)
    const tripled = doubleUp<SubcontractorTransportProfilingPAE>(assets, drsPageType)
    const tripledWithActions = tripled.map((data: any) => mapMoreActions(data, drsPageType, undefined, [PAETableMoreActionType.Delete, PAETableMoreActionType.Duplicate]))
    return tripledWithActions
  }
  let finalFields = DRSSubcontractorTransportProfilingsFields
  return <Wrapper>
    <FormPanel title={`Subcontractor Transport Profiling`}>
      <FormPAETable<DRSSubcontractorTransportProfilingRow>
        fields={finalFields}
        data={getTableData()}
        isSpecialDelete={true}
        showNo={true}
        editable={true}
        isSelectable={true}
        onEditRow={(row: DRSSubcontractorTransportProfilingRow) => {
          openAndSetData(getSupplierAgreementActivityRowData(row))
        }}
        showMoreActions={isPlan || isEdit}
        onRevert={handleRevert}
        onMarkZero={handleMarkZero}
        onDelete={handleDelete}
        onDuplicate={handleDuplicate}
      />
      {(isPlan || isEdit) &&
        <MarginTop>
          <CompCreateButton onClick={() => {
            openAndSetData(null)
          }}>
            Add New Transport
          </CompCreateButton>
        </MarginTop>
      }
    </FormPanel>

    {open &&
      <Modal<DRSSubcontractorTransportProfilingRow>
        maxWidth={'sm'}
        open={open}
        title='Subcontractor Transport Profiling'
        data={{
          rowData: modalData,
          fields: finalFields,
          columns: [
            {
              title: 'Transport Information',
              fieldKeys: [
                'supplier',
                'supplierAgreement',
                'activity',
                'comments',
              ],
            },
            {
              title: '',
              fieldKeys: [
                'weight',
                'startTime',
                'endTime',
                'quantity',
                'docketNumber',
              ],
            },
          ],
          extra: {
            depotId,
            stateId,
          }
        }}
        onSuccess={handleSave}
        onClose={closeModal}
        onCancel={closeModal}
      />
    }
  </Wrapper>
}

const Wrapper = styled.div`

`

export default CompDRSSubcontractorTransportProfiling